.buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
}

.buttons > button {
    width: 150px;
    margin: 0.25em;
    margin-bottom: 0;
}