
:root {
  color: white;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

body {
  background-color: black;
}

h1 {
  margin-left: 1em;
  margin-top: 0.5em;
}

button {
  background-color: black;
  color: white;
  border: none;
  height: 30px;
  font-weight: bold;
  font-size: medium;
  cursor: pointer;
}

button:hover {
  background-color:white;
  color: black;
  transition: 0.2s;
}

button:active {
  background-color:purple;
  color: white;
  transition: 0.2s;
}

iframe {
  border: none;
}

.splash_image {
  height: 40vh;
  object-fit: fill;
  margin-bottom: 4em;
}

.head_shot {
  overflow: hidden;
  border-radius: 50%;
  flex: none;
  margin-left: 2em;
  height: 200px;
  width: 200px /* seems necessary for Safari or gets distorted */
}

img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  max-height: 100%;
  max-width: 100%;
}

p {
  margin-left: 1em;
}

p.blurb {
  color: white;
  font-weight: bold;
  flex: 1;
  align-self: self-end;
  max-width: 300px;
  text-align: right;
}

.sermon_container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.sermon {
  min-width: 200px;
  height: auto;
  color: white;
  margin: 1em;
}

.sermon > p {
  text-align: center;
}

.flex-container {
  display: flex;
  align-self: center;
  margin: 3em;
  justify-content: center;
}

.text {
  justify-self: end;
}

.main_page {
  position: relative;
}

@media (max-width:480px) {
  .greeting {
    display: flex;
    position: unset;
    height: 10em;
    margin: 1em;
  }

  /* make this background at small sizes? */
  .splash_image {
    display: none;

  }
}

@media (min-width:481px) {
  .greeting {
    display: flex;
    position: absolute;
    top: 18vh;
    height: 20vh;
    width: 100%;
    justify-content: center;
  }
}

footer {
  text-align: center;
  margin: 1em;
}